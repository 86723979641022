.main-content {
    background-color: #ffffff;
    text-align: center;
}

@keyframes pulse {
    from {
      transform: scale(0.85);
    }
    50% {
      transform: scale(0.9);
    }
    to {
      transform: scale(0.85);
    }
  }

.pulse {
    animation-name: pulse ease-in;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.btn-sells {
    margin: -160px 0px 0px 0px;
    display: block;
    position: relative;
}

.image-container {
  position: relative;
}

.swiper-prods {
  position: absolute;
  top: 110px;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-sells {
  background-color: white;
  margin-right: 25px;
  margin-left: 25px;
  border-radius: 20px;
  padding: 20px;
}

.btn-sells-card {
  background-color: #012891;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 20px;
  font-weight: 900;
  text-transform: uppercase;
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (max-width: 260px) {
    .btn-sells {
        margin: -100px 0px 0px 0px;
    }
  }


@media only screen and (max-width: 330px) {
    .btn-sells {
        margin: -180px 0px 0px 0px;
    }
  }


@media only screen and (max-width: 767px) {
    .art {
      width: 100%;
      height: auto;
    }
  }

  @media only screen and (min-width: 767px) {
    .btn-sells {
      width: 90%
    }

    .card-sells {
      width: 400px;
    }
  }

